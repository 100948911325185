/*
 * These types are directly mapped to the backend Django models:
 * `python/protoapp/src/demo/models.py`
 *
 * Within each interface:
 * - The top keys are directly from the model.
 * - The bottom keys are computed fields from the model.
 */

export interface User {
  id: string;
  name: string;
  email: string;
  anonymous?: boolean;
  dev?: boolean;
  created_at: string;
  updated_at?: string;
  intercom_user_hash?: string;
  org_id?: string; // Additive Organization ID
  org_name?: string; // Organization Name
}

export interface JobOutput {
  name: string;
  path: string;
  size: number;
  created_at: string;
  type: JobOutputType;

  // Computed fields
  download_url: string;
}

export interface Job {
  id: string;
  name: string;
  status: 'PENDING' | 'COMPLETED' | 'FAILED';
  status_detail_message: string;
  created_at: string;
  updated_at?: string;

  // Computed fields
  outputs?: JobOutput[];
}

export enum JobOutputType {
  // Not entirely sure why, but eslint complains these are unused. For the
  // record, vscode/cursor seems to find all references to these just fine.
  /* eslint-disable no-unused-vars */
  ADDITIVE_IWP_ZIP = 'additive_iwp_zip',
  ADDITIVE_PDF_WP = 'additive_pdf_wp',
  ADDITIVE_EXCEL_WP = 'additive_excel_wp',
  RSM_PSI = 'rsm_psi',
  RSM_LEGACY = 'rsm_legacy',
  /* eslint-enable no-unused-vars */
}
