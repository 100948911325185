import {JobOutputType} from 'types/models';

export function jobTypeToName(type: JobOutputType) {
  switch (type) {
    case JobOutputType.ADDITIVE_PDF_WP:
      return 'PDF Workpaper';
    case JobOutputType.ADDITIVE_EXCEL_WP:
      return 'Additive Workpaper';
    case JobOutputType.RSM_LEGACY:
      return 'Trapeze Compatible Workpaper';
    case JobOutputType.RSM_PSI:
      return 'PartnerSight Import Workpaper';
    case JobOutputType.ADDITIVE_IWP_ZIP:
      return 'IWP Zip';
    default:
      return 'Other';
  }
}
