import {ErrorMessage} from 'components/errorMessage';
import {StyledDownloadLink} from 'components/link';
import {DashboardLayout} from 'layouts/dashboard';
import {useParams} from 'react-router-dom';
import {useGetJobByIdQuery} from 'reduxStore/services';
import {JobOutput, JobOutputType} from 'types/models';
import {enforceAuth} from 'utils/auth';
import {jobTypeToName} from 'utils/files';

import {ThanksInner} from './thanks';

export function Result() {
  const {jobId} = useParams();
  const {data: job, error: apiError, isLoading} = useGetJobByIdQuery(jobId ?? '');
  if (enforceAuth(apiError)) return;

  // Bucket job outputs by file type
  const jobOutputByType =
    job?.outputs?.reduce((acc: Map<JobOutputType, JobOutput[]>, output: JobOutput) => {
      const fileType = output.type;
      if (!acc.has(fileType)) {
        acc.set(fileType, []);
      }
      const outputs = acc.get(fileType)!;
      outputs.push(output);
      return acc;
    }, new Map<JobOutputType, JobOutput[]>()) ?? new Map<JobOutputType, JobOutput[]>();

  // Reorder the file types by the order dictated in fileTypeOrder
  function prioritizeFileType(
    map: Map<JobOutputType, JobOutput[]>,
    fileTypeOrder: JobOutputType[],
  ): Map<JobOutputType, JobOutput[]> {
    const orderedMap = new Map<JobOutputType, JobOutput[]>();

    fileTypeOrder.forEach((type) => {
      if (map.has(type)) {
        orderedMap.set(type, map.get(type)!);
      }
    });

    map.forEach((outputs, type) => {
      if (!fileTypeOrder.includes(type as JobOutputType)) {
        orderedMap.set(type, outputs);
      }
    });

    return orderedMap;
  }

  // Reorder the file types by the order dictated in https://github.com/additiveai/plus/issues/4388
  const prioritizedJobOutputByType = prioritizeFileType(jobOutputByType, [
    JobOutputType.ADDITIVE_EXCEL_WP,
    JobOutputType.RSM_PSI,
    JobOutputType.RSM_LEGACY,
    JobOutputType.ADDITIVE_PDF_WP,
    JobOutputType.ADDITIVE_IWP_ZIP,
  ]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Redux Toolkit thinks there is no `status` property
  const apiErrorStatus = +apiError?.status;

  return (
    <DashboardLayout homeLink>
      {isLoading ? (
        <p className="text-white">Loading results…</p>
      ) : !job || apiErrorStatus === 404 ? (
        <ErrorMessage
          error={new Error('Workpaper not found.')}
          detail={job?.status_detail_message}
        />
      ) : job?.status === 'PENDING' ? (
        <ThanksInner />
      ) : !prioritizedJobOutputByType?.size ? (
        <ErrorMessage
          error={new Error(job?.status_detail_message ?? 'No Workpaper outputs were generated.')}
        />
      ) : apiError ? (
        <ErrorMessage error={apiError} detail={job?.status_detail_message} />
      ) : (
        <>
          <h1 className="text-3xl text-white font-bold pb-6">Workpaper Results</h1>
          <h2 className="text-lg text-white pb-6">
            Your job has been successfully completed. You can download the files below.
          </h2>
          <div>
            {Array.from(prioritizedJobOutputByType.entries()).map(
              ([fileType, outputs]: [JobOutputType, JobOutput[]]) => {
                return (
                  <div key={fileType} className="pb-2">
                    <h2 className="text-xl text-white font-bold pb-2">{jobTypeToName(fileType)}</h2>
                    <ul className="results pb-6">
                      {outputs.map((jobOutput) => {
                        return (
                          <li key={jobOutput.name}>
                            <StyledDownloadLink
                              downloadURL={jobOutput.download_url}
                              fileType={fileType}
                              text={jobOutput.name}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              },
            )}
          </div>
        </>
      )}
    </DashboardLayout>
  );
}
